.mainContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  height: 100%;
  max-height: 100%;
  width: 85%;
  max-width: 85%;
  overflow: auto;
  margin-top: 75px;
  margin-left: 15%;
}

@media (max-width: 767.98px) {
  .mainContainer {
    width: 100%;
    max-width: 100%;
    margin-left: 0%;
  }
}

.mainContainerPadding {
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  height: 100%;
  max-height: 100%;
  width: 85%;
  max-width: 85%;
  overflow: auto;
  margin-top: 75px;
  margin-left: 15%;
}

/* TO AVOID SET PADDING IN ALL FEATURES WITHOUT NEED TO PUT CSS IN ROUTER */
/* IT'S SET PADDING IN MAIN CONTAINER CHILD */
